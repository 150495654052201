import { connect } from '../../../data/connect'
import PageContainer from '../../PageContainer'
import './Home.scss'
import { IonRouterLink } from '@ionic/react'
import { t } from 'i18next'
import React from 'react'

interface OwnProps {}

interface StateProps {}

interface DispatchProps {}

type HomePageProps = OwnProps & StateProps & DispatchProps

const HomePage: React.FC<HomePageProps> = () => {
  return (
    <PageContainer id='home-page' title='Iliya' isPrivate={false}>
      <h1>{t<string>('Branding sentence')}</h1>
      <p>
        {t<string>('HomeP1')} {t<string>('HomeP1_1')} {t<string>('HomeP1_2')}
      </p>
      <ul>
        <li>{'محیط کاربری ساده'}</li>
        <li>{'امکان ارسال تستی به سامانه مودیان'}</li>
        <li>{'ثبت چند مودی'}</li>
        <li>...</li>
      </ul>
      <p>
        {t<string>('HomeP2')} <IonRouterLink href='/support'>{t<string>('HomeP2_1')}</IonRouterLink>{' '}
        {t<string>('HomeP2_3')}
      </p>
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: React.memo(HomePage),
})
