import Menu from '../components/Menu'
import ProductListPage from '../pages-gtin/ProductListPage'
import Desk from '../pages-mfactor/desk/Desk'
import FactorListPage from '../pages-mfactor/factor/FactorListPage'
import ModiList from '../pages-mfactor/modi/ModiListPage'
import PersonListPage from '../pages-mfactor/person/PersonListPage'
import Account from '../pages/account/Account'
import AdminUsers from '../pages/admin/AdminUsers'
import AdminWebPush from '../pages/admin/AdminWebPush'
import AppBilling from '../pages/app-billing/AppBilling'
import AppCredentials from '../pages/app-credentials/AppCredentials'
import Apps from '../pages/apps/Apps'
import PlanInvoice from '../pages/plan/PlanInvoice'
import PlanInvoiceCallback from '../pages/plan/PlanInvoiceCallback'
import SelectPlanPeriod from '../pages/plan/SelectPlanPeriod'
import Privacy from '../pages/public/Privacy'
import Support from '../pages/public/Support'
import Terms from '../pages/public/Terms'
import Home from '../pages/public/home/Home'
import Login from '../pages/public/login/Login'
import LoginRedirect from '../pages/public/login/LoginRedirect'
import LogoutRedirect from '../pages/public/login/LogoutRedirect'
import Plans from '../pages/public/plans/Plans'
import { IonRouterOutlet, IonSplitPane } from '@ionic/react'
import React, { FC } from 'react'
import { Route, RouteComponentProps, withRouter } from 'react-router'

interface OwnProps extends RouteComponentProps {}
interface RoutesProps extends OwnProps {}

const MFactorRoutes: FC<RoutesProps> = ({ history, location, match, staticContext }) => {
  return (
    <IonSplitPane contentId='main'>
      <Menu history={history} location={location} match={match} staticContext={staticContext} />
      <IonRouterOutlet id='main'>
        <Route path='/' component={Home} exact />
        <Route path='/desk' component={Desk} exact />
        <Route path='/list' component={FactorListPage} exact />
        <Route path='/account' component={Account} />
        <Route path='/login' component={Login} />
        <Route path='/authcallback' component={LoginRedirect} />
        <Route path='/endsession' component={LogoutRedirect} exact />
        <Route path='/entity/modi' component={ModiList} />
        <Route path='/entity/person' component={PersonListPage} />
        <Route path='/entity/kala' component={ProductListPage} />
        <Route path='/apps' render={() => <Apps />} exact />
        <Route path='/apps/billing' render={() => <AppBilling />} exact />
        <Route path='/apps/credentials' render={() => <AppCredentials />} exact />
        <Route path='/admin/users' render={() => <AdminUsers />} exact />
        <Route path='/admin/web-push' render={() => <AdminWebPush />} exact />
        <Route path='/plans' render={() => <Plans />} exact={true} />
        <Route path='/plan/select/:planType' component={SelectPlanPeriod} exact={true} />
        <Route path='/plan/invoice/:factorPublicId' component={PlanInvoice} exact={true} />
        <Route path='/plan/invoice/callback' component={PlanInvoiceCallback} exact={true} />
        <Route path='/support' component={Support} />
        <Route path='/terms' component={Terms} />
        <Route path='/privacy' component={Privacy} />
        {/* <Switch>
        <Route path='*'>
          <Redirect to='/' />
        </Route>
      </Switch> */}
      </IonRouterOutlet>
    </IonSplitPane>
  )
}

export default React.memo(withRouter(MFactorRoutes))
