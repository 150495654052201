import { connect } from '../../data/connect'
import AppContainer from '../../pages/AppContainer'
import AppListButton from '../../pages/apps/AppListButton'
import FactorList from '../factor/FactorList'
import React, { FC } from 'react'

interface OwnProps {}
interface StateProps {}
interface DispatchProps {}
interface FactorListPageProp extends OwnProps, StateProps, DispatchProps {}
const FactorListPage: FC<FactorListPageProp> = ({}) => {
  return (
    <AppContainer id='factor-list' actionButtonsStart={<AppListButton hideSearchIcon={true} />}>
      <FactorList />
      <div className='ion-padding-vertical'></div>
    </AppContainer>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({}),
  mapDispatchToProps: {},
  component: React.memo(FactorListPage),
})
