import React from 'react'
import { sha } from '~build/info'
import now from '~build/time'

const CopyRightAndVersion = () => {
  return (
    <div className='copyright ion-padding-start dir-ltr'>
      &copy; 2023, Iliyax.ir, <span title={now + sha?.substring(0, 6)}>v{APP_VERSION}</span>
    </div>
  )
}

export default React.memo(CopyRightAndVersion)
