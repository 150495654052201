import { error, log } from '../../components/util/Log'
import TkSpinner from '../../components/util/TkSpinner'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { setIsAuthenticated, setNet } from '../../data/user/user.actions'
import { App } from '../../models/App'
import { Modi } from '../../models/Modi'
import { MFactorService } from '../../services/MFactorService'
import ModiEditModal from './ModiEditModal'
import { IonItem, IonLabel, IonSearchbar } from '@ionic/react'
import { useIonModal, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {
  onSuccess?: Function
}
interface StateProps {
  app?: App
  net: boolean
}
interface DispatchProps {
  setNet: typeof setNet
  setIsAuthenticated: typeof setIsAuthenticated
}
interface ModiListProps extends OwnProps, StateProps, DispatchProps {}
const ModiList: FC<ModiListProps> = ({ app, net, setIsAuthenticated }) => {
  const [items, setItems] = useState<any[]>([])
  const [toast] = useIonToast()
  const [search, setSearch] = useState('')
  const [appPublicId, setAppPublicId] = useState<string>()
  const [present, dismiss] = useIonModal(ModiEditModal, {
    appPublicId,
    onDismiss: (data: string, role: string) => {
      dismiss(data, role)
      fetchItems()
    },
  })

  const fetchItems = async () => {
    setNet(true)
    try {
      const res: AxiosResponse = await MFactorService.Instance.get('/v1/tax-profile')
      log('res', res.data)
      if (res.status === 200) {
        if (Array.isArray(res.data)) {
          setItems(res.data)
        } else {
          error('Expected an array but got:', res.data)
        }
      }
    } catch (err: any) {
      failure(err?.message, toast)
      if (err?.message?.endsWith('401')) {
        setIsAuthenticated(false)
      }
      error('Modies#fetchItems', err)
    }
    setNet(false)
  }

  useEffect(() => {
    fetchItems()
    return () => {}
  }, []) // eslint-disable-line

  useEffect(() => {
    log('items state updated', items)
  }, [items])

  return (
    <>
      {net && <TkSpinner />}
      {!net && <IonSearchbar onIonChange={(e: any) => setSearch(e.detail.value?.toLocaleLowerCase())} />}
      {!net &&
        items
          ?.filter((a: Modi) => !a.name || a.name?.toLocaleLowerCase()?.includes(search))
          .map((modi: Modi) => (
            <IonItem
              key={modi.appPublicId}
              button={true}
              color={app?.publicId === modi.appPublicId ? 'medium' : ''}
              onClick={() => {
                setAppPublicId(modi.appPublicId)
                present({
                  breakpoints: [0, 1],
                  initialBreakpoint: 1,
                  backdropDismiss: false,
                })
              }}
            >
              <IonLabel>
                {modi.name}
                <p>{modi.appPublicId}</p>
              </IonLabel>
            </IonItem>
          ))}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setNet,
    setIsAuthenticated,
  },
  component: React.memo(ModiList),
})
