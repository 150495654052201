import { dateTimeDisplay } from '../../components/util/Calendar'
import { error, log } from '../../components/util/Log'
import { failure, info } from '../../components/util/Toast'
import { fixKafYe } from '../../components/util/Word'
import currencySign from '../../components/util/currencySign'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { setRefreshFactorList } from '../../data/factor/factor.actions'
import { setSyncing } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import Factor from '../../models/mfactor/Factor'
import { MFactorDbService } from '../../services/MFactorDbService'
import FactorEditModal from './FactorEditModal'
import { IonButton, IonButtons, IonSearchbar } from '@ionic/react'
import { IonText, useIonModal } from '@ionic/react'
import { IonIcon, IonItem, IonItemOption, IonItemOptions } from '@ionic/react'
import { IonItemSliding, IonLabel, useIonToast } from '@ionic/react'
import { hasUnsyncedChanges } from '@nozbe/watermelondb/sync'
import { t } from 'i18next'
import { chevronBack, chevronForward, sync, trashBin } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'

interface OwnProps {}
interface StateProps {
  app?: App
  lang?: string
  syncing: number
  refreshFactorList: boolean
}
interface DispatchProps {
  setSyncing: typeof setSyncing
  setRefreshFactorList: typeof setRefreshFactorList
}
interface FactorListProps extends OwnProps, StateProps, DispatchProps {}
const FactorList: FC<FactorListProps> = ({
  app,
  lang,
  setSyncing,
  syncing,
  refreshFactorList,
  setRefreshFactorList,
}) => {
  const database = MFactorDbService.Instance.db
  const [toast] = useIonToast()
  const [factors, setFactors] = useState<Factor[]>([])
  const [deleting, setDeleting] = useState(false)
  const [syncNeeded, setSyncNeeded] = useState(false)
  const [showSyncButton, setShowSyncButton] = useState(false)
  const [showPayers, setShowPayers] = useState(false)
  const [search, setSearch] = useState<string>()
  const [skip, setSkip] = useState<number>(0)
  const [id, setId] = useState<string>()
  const [present, dismiss] = useIonModal(FactorEditModal, {
    id,
    onDismiss: (data: string, role: string) => {
      dismiss(data, role)
      if (role === 'deleted') fetchFactors()
    },
  })

  const fetchFactors = async () => {
    if (app?.publicId) {
      const facts: Factor[] = await MFactorDbService.Instance.searchFactor(app?.publicId, skip, search)
      if (!!facts?.length) {
        setFactors(facts.map((fac: Factor) => fac.workaroundSetModel()))
        setShowSyncButton(false)
      } else {
        if (search || skip) setFactors([])
        else setShowSyncButton(true)
      }
    }
  }

  const deleteFactor = async (factor: Factor) => {
    setDeleting(true)
    const fac: Factor = await database.get<Factor>('factor').find(factor.id)
    fac.markAsDeletedForReal()
    await fetchFactors()
    setDeleting(false)
  }

  async function checkUnsyncedChanges() {
    try {
      const res = await hasUnsyncedChanges({ database })
      setSyncNeeded(res)
    } catch (err) {
      error('checkUnsyncedChanges', err)
    }
  }

  async function factorDbSync() {
    if (!app?.publicId) {
      info('Select an app first', toast)
      return
    }
    if (!!syncing) return
    const syncInterval = setInterval(() => {
      setSyncing((syncing += 0.05))
    }, 500)
    setTimeout(async () => {
      try {
        await MFactorDbService.Instance.syncDb(app.publicId)
      } catch (err: any) {
        if (err?.response?.status !== 0) failure(remoteErrorExtractor(err), toast)
      }
      setRefreshFactorList(!refreshFactorList)
      clearInterval(syncInterval)
      setSyncing(0)
    }, 0)
  }

  const fillPersonNameCache = () => {
    setShowPayers(false)
    setTimeout(() => {
      const promises: Promise<string>[] = []
      factors.forEach((f: Factor) => promises.push(MFactorDbService.Instance.lookupPersonName(f.payer, f.appPublicId)))
      Promise.allSettled(promises)
        .then(() => {
          setTimeout(() => {
            setShowPayers(true)
          }, 1000)
        })
        .catch((err) => error('PersonName resolve failure', err))
    }, 300)
  }

  useEffect(() => {
    fillPersonNameCache()
  }, [factors])

  useEffect(() => {
    if (syncNeeded) factorDbSync()
  }, [syncNeeded])

  useEffect(() => {
    fetchFactors()
  }, [refreshFactorList, app?.publicId, search, skip])

  useEffect(() => {
    checkUnsyncedChanges()
  }, [refreshFactorList, deleting])

  useEffect(() => {
    log('syncing', syncing)
  }, [syncing])

  return (
    <>
      <IonSearchbar
        debounce={500}
        placeholder={t('Search factor')}
        onIonInput={(e: any) => setSearch(fixKafYe(e.detail.value))}
      ></IonSearchbar>
      {showSyncButton && (
        <div className='ion-text-center'>
          <IonButton onClick={factorDbSync}>
            <IonIcon icon={sync} />
          </IonButton>
        </div>
      )}
      {!deleting &&
        factors.map((f: Factor) => (
          <IonItemSliding key={f.id}>
            <IonItem
              button={true}
              onClick={() => {
                setId(f.id)
                present()
              }}
            >
              {(MFactorDbService.Instance.personCache.get(f.payer) || showPayers) && (
                <IonText slot={lang === 'fa' ? 'start' : 'end'}>
                  {f.payer ? MFactorDbService.Instance.personCache.get(f.payer) : ''}
                </IonText>
              )}
              {!isMobile && <IonText slot={lang === 'fa' ? 'start' : 'end'}>{f?.id}</IonText>}
              <IonLabel className='dir-ltr'>
                {currencySign(f.currency)} {f.total?.toLocaleString(navigator.language)}
                <p>
                  <span
                    title={f.factorDate}
                    style={{ display: 'inline-block' }}
                    className={lang === 'fa' ? 'dir-rtl' : 'dir-ltr'}
                  >
                    {dateTimeDisplay(f.factorDate, lang)}
                  </span>
                </p>
              </IonLabel>
            </IonItem>
            <IonItemOptions>
              <IonItemOption color={'danger'} onClick={() => deleteFactor(f)} disabled={deleting}>
                <IonIcon slot='icon-only' icon={trashBin} />
              </IonItemOption>
            </IonItemOptions>
          </IonItemSliding>
        ))}
      <IonItem>
        <IonButtons slot='start'>
          <IonButton disabled={skip === 0} onClick={() => setSkip(skip - 5)}>
            <IonIcon icon={document.documentElement.dir === 'rtl' ? chevronForward : chevronBack} />
          </IonButton>
        </IonButtons>
        <IonButtons slot='end'>
          <IonButton onClick={() => setSkip(skip + 5)} disabled={factors.length < 5}>
            <IonIcon icon={document.documentElement.dir === 'rtl' ? chevronBack : chevronForward} />
          </IonButton>
        </IonButtons>
      </IonItem>
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    lang: state.user.lang,
    syncing: state.factor.syncing,
    refreshFactorList: state.factor.refreshFactorList,
  }),
  mapDispatchToProps: {
    setSyncing,
    setRefreshFactorList,
  },
  component: React.memo(FactorList),
})
