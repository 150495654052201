/* App CSS */
import './App.scss'
import './assets/css/flex.scss'
import ErrorBoundary from './components/ErrorBoundary'
import SplashLoading from './components/SplashLoading'
import { log } from './components/util/Log'
import remoteErrorExtractor from './components/util/remoteErrorExtractor'
import { AppContextProvider } from './data/AppContext'
import { connect } from './data/connect'
import {
  loadFactorData,
  setFactorItemList,
  setRefreshPersonList,
  setRefreshPersonSelect,
  setRefreshProductList,
  setRefreshProductSelect,
  setSyncing,
} from './data/factor/factor.actions'
import { loadConfData } from './data/sessions/sessions.actions'
import { loadUserData } from './data/user/user.actions'
import './i18n/config'
import { App } from './models/App'
import MFactorRoutes from './routes/MFactorRoutes'
import WebRoutes from './routes/WebRoutes'
import { GtinDbService } from './services/GtinDbService'
import { MFactorDbService } from './services/MFactorDbService'

/* Theme variables */
import './theme/variables.scss'
import { IonApp, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import React, { Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'vazirmatn/Vazirmatn-font-face.css'

setupIonicReact()

const MyApp: React.FC = () => {
  return (
    <ErrorBoundary>
      <AppContextProvider>
        <Suspense fallback={<SplashLoading />}>
          <IonicAppConnected />
        </Suspense>
      </AppContextProvider>
    </ErrorBoundary>
  )
}

interface StateProps {
  darkMode: boolean
  lang: string
  app?: App
  syncing: number
  refreshPersonList: boolean
  refreshPersonSelect: boolean
  refreshProductList: boolean
  refreshProductSelect: boolean
}

interface DispatchProps {
  loadConfData: typeof loadConfData
  loadUserData: typeof loadUserData
  loadFactorData: typeof loadFactorData
  setSyncing: typeof setSyncing
  setRefreshPersonList: typeof setRefreshPersonList
  setRefreshPersonSelect: typeof setRefreshPersonSelect
  setRefreshProductList: typeof setRefreshProductList
  setRefreshProductSelect: typeof setRefreshProductSelect
  setFactorItemList: typeof setFactorItemList
}

interface IonicAppProps extends StateProps, DispatchProps {}

const IonicApp: React.FC<IonicAppProps> = ({
  darkMode,
  lang,
  app,
  syncing,
  refreshPersonList,
  refreshPersonSelect,
  refreshProductList,
  refreshProductSelect,
  loadConfData,
  loadUserData,
  loadFactorData,
  setSyncing,
  setRefreshPersonList,
  setRefreshPersonSelect,
  setRefreshProductList,
  setRefreshProductSelect,
  setFactorItemList,
}) => {
  const [firstTime, setFirstTime] = useState(true)
  const { i18n } = useTranslation()

  const resetDatabases = async () => {
    await MFactorDbService.Instance.db.write(async () => {
      await MFactorDbService.Instance.db.unsafeResetDatabase()
      log('factor db reset')
    })
    await GtinDbService.Instance.db.write(async () => {
      await GtinDbService.Instance.db.unsafeResetDatabase()
      log('product db reset')
    })
  }

  async function personDbSync() {
    if (!!syncing || !app?.publicId) return
    const syncInterval = setInterval(() => {
      setSyncing((syncing += 0.05))
    }, 500)
    try {
      await MFactorDbService.Instance.syncDb(app.publicId)
      console.log('Person db synced')
    } catch (err: any) {
      if (err?.response?.status !== 0) log(remoteErrorExtractor(err))
    }
    clearInterval(syncInterval)
    setRefreshPersonSelect(!refreshPersonSelect)
    setSyncing(0)
  }

  async function productDbSync() {
    if (!!syncing || !app?.publicId) return
    const syncInterval = setInterval(() => {
      setSyncing((syncing += 0.05))
    }, 500)
    try {
      await GtinDbService.Instance.syncDb(app?.publicId)
      console.log('GTIN db synced')
    } catch (err: any) {
      if (err?.response?.status !== 0) log(remoteErrorExtractor(err))
    }
    clearInterval(syncInterval)
    setRefreshProductSelect(!refreshProductSelect)
    setSyncing(0)
  }

  const refreshCaches = async () => {
    if (!firstTime) {
      await resetDatabases()
      await personDbSync()
      await productDbSync()
      setRefreshPersonList(!refreshPersonList)
      setRefreshProductList(!refreshProductList)
      setFactorItemList([])
    }
  }

  useEffect(() => {
    personDbSync()
  }, [refreshPersonList]) // eslint-disable-line

  useEffect(() => {
    productDbSync()
  }, [refreshProductList]) // eslint-disable-line

  useEffect(() => {
    if (app?.publicId) refreshCaches()
  }, [app?.publicId]) // eslint-disable-line

  useEffect(() => {
    if (!!lang && !firstTime) {
      window.location.reload()
    }
    if (!!lang) {
      setFirstTime(false)
    }
    if (lang === 'fa') {
      document.documentElement.setAttribute('dir', 'rtl')
      document.documentElement.setAttribute('lang', 'fa')
      i18n.changeLanguage('fa')
      document.title = import.meta.env.VITE_APP_NAME === 'taak' ? 'تاک کلود' : 'میکرو فاکتور'
    } else if (lang === 'en') {
      document.documentElement.setAttribute('dir', 'ltr')
      document.documentElement.setAttribute('lang', 'en')
      i18n.changeLanguage('en')
      document.title = import.meta.env.VITE_APP_NAME === 'taak' ? 'Taakcloud' : 'µFactor'
    }
    return () => {}
  }, [lang]) // eslint-disable-line

  useEffect(() => {
    loadUserData()
    loadConfData()
    loadFactorData()
    return () => {}
  }, []) // eslint-disable-line

  return (
    <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
      <IonReactRouter>
        {import.meta.env.VITE_APP_NAME === 'taak' && <WebRoutes />}
        {import.meta.env.VITE_APP_NAME === 'mFactor' && <MFactorRoutes />}
      </IonReactRouter>
    </IonApp>
  )
}

export default React.memo(MyApp)

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    lang: state.user.lang,
    app: state.user.app,
    syncing: state.user.syncing,
    refreshPersonList: state.factor.refreshPersonList,
    refreshPersonSelect: state.factor.refreshPersonSelect,
    refreshProductList: state.factor.refreshProductList,
    refreshProductSelect: state.factor.refreshProductSelect,
  }),
  mapDispatchToProps: {
    loadConfData,
    loadUserData,
    loadFactorData,
    setSyncing,
    setRefreshPersonList,
    setRefreshPersonSelect,
    setRefreshProductList,
    setRefreshProductSelect,
    setFactorItemList,
  },
  component: React.memo(IonicApp),
})
