import ModiEditModal from './ModiEditModal'
import { IonButton, IonIcon, useIonModal } from '@ionic/react'
import { t } from 'i18next'
import { add } from 'ionicons/icons'
import React, { FC } from 'react'

interface ModiAddButtonProps {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}
const ModiAddButton: FC<ModiAddButtonProps> = ({ onDismiss }) => {
  const [present, dismiss] = useIonModal(ModiEditModal, {
    onDismiss: (data: string, role: string) => dismiss(data, role),
  })
  return (
    <>
      <IonButton
        onClick={() =>
          present({
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            backdropDismiss: false,
          })
        }
      >
        <IonIcon icon={add} slot='start' />
        {t<string>('New modi')}
      </IonButton>
    </>
  )
}

export default React.memo(ModiAddButton)
