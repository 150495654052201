import { connect } from '../data/connect'
import { App } from '../models/App'
import AppContainer from '../pages/AppContainer'
import AppListButton from '../pages/apps/AppListButton'
import ProductAddButton from './ProductAddButton'
import ProductList from './ProductList'
import React, { FC } from 'react'

interface OwnProps {}
interface StateProps {
  app?: App
}
interface DispatchProps {}
interface ProductListPageProp extends OwnProps, StateProps, DispatchProps {}
const ProductListPage: FC<ProductListPageProp> = ({ app }) => {
  return (
    <AppContainer
      id='product-list'
      actionButtonsStart={!!app ? <ProductAddButton onDismiss={() => {}} /> : <AppListButton hideSearchIcon={true} />}
    >
      <ProductList />
      <div className='ion-padding-vertical'></div>
    </AppContainer>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  mapDispatchToProps: {},
  component: React.memo(ProductListPage),
})
