import TkSpinner from '../components/util/TkSpinner'
import { failure } from '../components/util/Toast'
import { fixKafYe } from '../components/util/Word'
import currencySign from '../components/util/currencySign'
import remoteErrorExtractor from '../components/util/remoteErrorExtractor'
import { connect } from '../data/connect'
import { setRefreshProductList } from '../data/factor/factor.actions'
import { setIsAuthenticated } from '../data/user/user.actions'
import { App } from '../models/App'
import Product from '../models/gtin/Product'
import { GtinService } from '../services/GtinService'
import ProductEditModal from './ProductEditModal'
import { IonButtons, IonChip, IonSearchbar } from '@ionic/react'
import { IonIcon, IonItem, IonItemOption, IonItemOptions, useIonModal } from '@ionic/react'
import { IonItemSliding, IonLabel, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { trashBin } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {}
interface StateProps {
  app?: App
  lang?: string
  refreshProductList: boolean
}
interface DispatchProps {
  setRefreshProductList: typeof setRefreshProductList
  setIsAuthenticated: typeof setIsAuthenticated
}
interface ProductListProps extends OwnProps, StateProps, DispatchProps {}
const ProductList: FC<ProductListProps> = ({
  app,
  lang,
  refreshProductList,
  setRefreshProductList,
  setIsAuthenticated,
}) => {
  const [toast] = useIonToast()
  const [products, setProducts] = useState<Product[]>([])
  const [deleting, setDeleting] = useState(false)
  const [gtin, setGtin] = useState<string>()
  const [present, dismiss] = useIonModal(ProductEditModal, {
    gtin,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  })
  const [search, setSearch] = useState<string>()
  const [inprogress, setInprogress] = useState(false)

  const fetchProducts = async () => {
    if (app?.publicId) {
      setInprogress(true)
      try {
        const res: AxiosResponse = await GtinService.Instance.get(`/v1/app-product/${app?.publicId}`, {})
        if (res.status === 200) setProducts(res.data)
      } catch (err: any) {
        if (err?.message?.endsWith('401')) {
          setIsAuthenticated(false)
        }
        failure(remoteErrorExtractor(err), toast)
      }
      setInprogress(false)
    }
  }

  const deleteProduct = async (product: Product) => {
    setDeleting(true)
    try {
      const res: AxiosResponse = await GtinService.Instance.delete(
        `/v1/app-product/${app?.publicId}/${product.gtin}`,
        {}
      )
      if (res.status === 204) await fetchProducts()
      setRefreshProductList(!refreshProductList)
    } catch (err) {
      failure(remoteErrorExtractor(err), toast)
    }
    setDeleting(false)
  }

  useEffect(() => {
    fetchProducts()
  }, [refreshProductList, search, app?.publicId])

  return (
    <>
      <IonSearchbar
        debounce={500}
        placeholder={t('Search product')}
        onIonInput={(e: any) => setSearch(fixKafYe(e.detail.value))}
      ></IonSearchbar>
      {inprogress && <TkSpinner />}
      {products.map((product: Product) => (
        <IonItemSliding key={product.id}>
          <IonItem
            button={true}
            onClick={() => {
              setGtin(product.gtin)
              present({
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                backdropDismiss: false,
              })
            }}
          >
            <IonLabel>
              {lang === 'fa' && (product.nameFa || product.name)}
              {lang !== 'fa' && (product.name || product.nameFa)}
              <p>{product.gtin}</p>
            </IonLabel>
            <IonButtons slot='end'>
              {!!product?.price && (
                <IonChip className='dir-ltr'>
                  {currencySign(product?.currency)} {(+product?.price).toLocaleString(navigator.language)}
                </IonChip>
              )}
            </IonButtons>
          </IonItem>
          <IonItemOptions>
            <IonItemOption color={'danger'} onClick={() => deleteProduct(product)} disabled={deleting}>
              <IonIcon slot='icon-only' icon={trashBin} />
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      ))}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    lang: state.user.lang,
    refreshProductList: state.factor.refreshProductList,
  }),
  mapDispatchToProps: {
    setRefreshProductList,
    setIsAuthenticated,
  },
  component: React.memo(ProductList),
})
