import { generateRandomId } from '../../components/util/Random'
import { failure } from '../../components/util/Toast'
import { fixKafYe } from '../../components/util/Word'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { mainSlot, minorSlot } from '../../components/util/resolveSlot'
import { connect } from '../../data/connect'
import { setRefreshPersonList } from '../../data/factor/factor.actions'
import { App } from '../../models/App'
import Person from '../../models/mfactor/Person'
import { MFactorService } from '../../services/MFactorService'
import PersonAddField from './PersonAddField'
import { IonButton, IonButtons, IonCol, IonItem } from '@ionic/react'
import { IonReorder, IonReorderGroup, IonSegment } from '@ionic/react'
import { IonSegmentButton, IonText, ItemReorderEventDetail } from '@ionic/react'
import { IonContent, IonFooter, IonPage } from '@ionic/react'
import { IonRow, useIonToast } from '@ionic/react'
import { IonHeader, IonIcon, IonInput, IonSpinner } from '@ionic/react'
import { IonTitle, IonToolbar } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { add, business, personCircle } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {
  id?: string
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}
interface StateProps {
  hand: 'right' | 'left'
  app?: App
  refreshPersonList: boolean
}
interface DispatchProps {
  setRefreshPersonList: typeof setRefreshPersonList
}
interface PersonEditModalProps extends OwnProps, StateProps, DispatchProps {}
const PersonEditModal: FC<PersonEditModalProps> = ({
  id,
  onDismiss,
  hand,
  app,
  refreshPersonList,
  setRefreshPersonList,
}) => {
  const [person, setPerson] = useState<Person | any>({
    id,
    personType: 'REAL',
  })
  const [fields, setFields] = useState<any[]>([])
  const [inprogress, setInprogress] = useState(false)
  const [toast] = useIonToast()

  const findById = async () => {
    if (id) {
      try {
        const res: AxiosResponse = await MFactorService.Instance.get(`/v1/person/${app?.publicId}/${id}/get`, {})
        if (res.status === 200) {
          setPerson(res.data)
          if (res.data.detail) setFields(JSON.parse(res.data.detail)?.fields || [])
        }
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      }
    }
  }

  const store = async () => {
    setInprogress(true)
    if (app?.publicId) {
      fields.map((f: any, idx: number) => {
        // Re-index
        f.r = idx
        return f
      })
      if (id) {
        try {
          const res: AxiosResponse = await MFactorService.Instance.put(`/v1/person/${app?.publicId}/${id}`, {
            id: id,
            personType: person.personType,
            name: fixKafYe(person.name) || person.name,
            nationalId: person.nationalId,
            detail: JSON.stringify({
              ...JSON.parse(person.detail),
              fields,
            }),
          })
        } catch (err) {
          failure(remoteErrorExtractor(err), toast)
        }
      } else {
        try {
          const res: AxiosResponse = await MFactorService.Instance.post(`/v1/person/${app?.publicId}`, {
            id: generateRandomId(),
            appPublicId: app.publicId,
            personType: person.personType,
            name: fixKafYe(person.name) || person.name,
            nationalId: person.nationalId,
            detail: JSON.stringify({ fields }),
          })
        } catch (err) {
          failure(remoteErrorExtractor(err), toast)
        }
      }

      setRefreshPersonList(!refreshPersonList)
      setPerson(undefined)
      onDismiss(person, 'edited')
    } else {
      failure('Please select your app first', toast)
    }
    setInprogress(false)
  }

  const addToDetail = (key: string, value: string) => {
    setFields((fields) => [...fields, { r: fields.length, k: key, v: value }])
  }

  const setDetailKeyValue = (key: string, value: string) => {
    const elem = fields.find((f: any) => f.k === key)
    elem.v = value
    fields.map((x: any) => (x.k === key ? { ...elem } : x))
    setFields(fields)
  }

  const handleReorder = (evt: CustomEvent<ItemReorderEventDetail>) => {
    const movedItem = fields.splice(evt.detail.from, 1)[0]
    fields.splice(evt.detail.to, 0, movedItem)
    setFields(fields)
    evt.detail.complete()
  }

  useEffect(() => {
    findById()
  }, [])

  useEffect(() => {
    // log('FIELDS before update', JSON.stringify(fields))
  }, [fields])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => onDismiss(null, 'cancel')}>{t<string>('Cancel')}</IonButton>
          </IonButtons>
          <IonTitle>{t<string>(person?._raw?.id ? 'Edit person' : 'New person')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonSegment
          value={person?.personType}
          onIonChange={(e) => setPerson({ ...person, personType: e.detail.value })}
        >
          <IonSegmentButton value='REAL'>
            <IonIcon icon={personCircle} />
          </IonSegmentButton>
          <IonSegmentButton value='COMPANY'>
            <IonIcon icon={business} />
          </IonSegmentButton>
        </IonSegment>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={person?.name}
              onIonInput={(e) => setPerson({ ...person, name: e.detail.value })}
              label={t('Name in', { personType: t(person?.personType) })}
              placeholder={t('Name')}
              required={true}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={person?.nationalId}
              onIonInput={(e) => setPerson({ ...person, nationalId: e.detail.value })}
              label={t('National Id')}
              placeholder={t('National Id')}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonReorderGroup disabled={false} onIonItemReorder={handleReorder}>
          {fields.map((f: any, idx: number) => (
            <IonItem key={`${idx}_${f.k}_${f.v}`} className='ion-padding-horizontal'>
              <IonReorder slot='start' />
              <IonInput
                value={f.v}
                onIonInput={(e: any) => setDetailKeyValue(f.k, e.detail.value)}
                placeholder={f.k}
                label={f.k}
              />
              <IonText>{f.r}</IonText>
            </IonItem>
          ))}
        </IonReorderGroup>
        <div className='ion-padding-horizontal'>
          <PersonAddField onAdd={(key: string, value: string) => addToDetail(key, value)} />
        </div>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => store()} disabled={inprogress} color='primary' fill='outline'>
              {!inprogress && <IonIcon icon={add} slot='start' />}
              {inprogress && <IonSpinner slot='start' />}
              {t<string>('Save')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    hand: state.user.hand,
    app: state.user.app,
    refreshPersonList: state.factor.refreshPersonList,
  }),
  mapDispatchToProps: {
    setRefreshPersonList,
  },
  component: React.memo(PersonEditModal),
})
