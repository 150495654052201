import { connect } from '../../data/connect'
import PageContainer from '../../pages/PageContainer'
import ModiAddButton from './ModiAddButton'
import ModiList from './ModiList'
import React, { FC } from 'react'

interface OwnProps {}
interface StateProps {}
interface DispatchProps {}
interface ModiListPageProp extends OwnProps, StateProps, DispatchProps {}
const ModiListPage: FC<ModiListPageProp> = ({}) => {
  return (
    <PageContainer id='modi-list' isPrivate={true} actionButtonsStart={<ModiAddButton onDismiss={() => {}} />}>
      <ModiList />
      <div className='ion-padding-vertical'></div>
    </PageContainer>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {},
  component: React.memo(ModiListPage),
})
