import TkSpinner from '../../components/util/TkSpinner'
import { failure } from '../../components/util/Toast'
import { fixKafYe } from '../../components/util/Word'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { connect } from '../../data/connect'
import { setRefreshPersonList } from '../../data/factor/factor.actions'
import { setIsAuthenticated } from '../../data/user/user.actions'
import { App } from '../../models/App'
import Person from '../../models/mfactor/Person'
import { MFactorService } from '../../services/MFactorService'
import PersonEditModal from './PersonEditModal'
import { IonSearchbar } from '@ionic/react'
import { IonIcon, IonItem, IonItemOption } from '@ionic/react'
import { IonItemOptions, useIonModal } from '@ionic/react'
import { IonItemSliding, IonLabel, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import { business, personCircle, trashBin } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {}
interface StateProps {
  app?: App
  lang?: string
  refreshPersonList: boolean
}
interface DispatchProps {
  setRefreshPersonList: typeof setRefreshPersonList
  setIsAuthenticated: typeof setIsAuthenticated
}
interface PersonListProps extends OwnProps, StateProps, DispatchProps {}
const PersonList: FC<PersonListProps> = ({ app, refreshPersonList, setIsAuthenticated }) => {
  const [toast] = useIonToast()
  const [people, setPeople] = useState<Person[]>([])
  const [deleting, setDeleting] = useState(false)
  const [id, setId] = useState<string>()
  const [inprogress, setInprogress] = useState(false)
  const [present, dismiss] = useIonModal(PersonEditModal, {
    id,
    onDismiss: (data: string, role: string) => dismiss(data, role),
  })
  const [search, setSearch] = useState<string>()

  const fetchPeople = async () => {
    if (app?.publicId) {
      setInprogress(true)

      try {
        const res: AxiosResponse = await MFactorService.Instance.get(`/v1/person/${app?.publicId}`, {})
        if (res.status === 200) setPeople(res.data)
      } catch (err: any) {
        if (err?.message?.endsWith('401')) {
          setIsAuthenticated(false)
        }
        failure(remoteErrorExtractor(err), toast)
      }
      setInprogress(false)
    }
  }

  const deletePerson = async (person: Person) => {
    if (app?.publicId) {
      setDeleting(true)
      try {
        const res: AxiosResponse = await MFactorService.Instance.delete(`/v1/person/${app?.publicId}/${person.id}`, {})
        if (res.status === 204) await fetchPeople()
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      }
      setRefreshPersonList(!refreshPersonList)
      setDeleting(false)
    }
  }

  useEffect(() => {
    fetchPeople()
  }, [refreshPersonList, search, app?.publicId])

  return (
    <>
      <IonSearchbar
        debounce={500}
        placeholder={t('Search person')}
        onIonInput={(e: any) => setSearch(fixKafYe(e.detail.value))}
      ></IonSearchbar>
      {inprogress && <TkSpinner />}
      {people.map((p: Person) => (
        <IonItemSliding key={p.id}>
          <IonItem
            button={true}
            onClick={() => {
              setId(p.id)
              present()
            }}
          >
            {p.personType === 'REAL' && <IonIcon slot='start' icon={personCircle} />}
            {p.personType === 'COMPANY' && <IonIcon slot='start' icon={business} />}
            <IonLabel title={p.id}>{p.name}</IonLabel>
          </IonItem>
          <IonItemOptions>
            <IonItemOption color={'danger'} onClick={() => deletePerson(p)} disabled={deleting}>
              <IonIcon slot='icon-only' icon={trashBin} />
            </IonItemOption>
          </IonItemOptions>
        </IonItemSliding>
      ))}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
    refreshPersonList: state.factor.refreshPersonList,
  }),
  mapDispatchToProps: {
    setRefreshPersonList,
    setIsAuthenticated,
  },
  component: React.memo(PersonList),
})
