import { log } from '../../components/util/Log'
import { convertToEnglish } from '../../components/util/Number'
import { failure } from '../../components/util/Toast'
import remoteErrorExtractor from '../../components/util/remoteErrorExtractor'
import { mainSlot, minorSlot } from '../../components/util/resolveSlot'
import { connect } from '../../data/connect'
import { Modi } from '../../models/Modi'
import { AppApiService } from '../../services/AppApiService'
import { MFactorService } from '../../services/MFactorService'
import { IonButton, IonButtons, IonCol, IonItem, IonLabel, IonTextarea, IonToggle } from '@ionic/react'
import { IonContent, IonFooter, IonPage } from '@ionic/react'
import { IonRow, useIonToast } from '@ionic/react'
import { IonHeader, IonIcon, IonInput, IonSpinner } from '@ionic/react'
import { IonTitle, IonToolbar } from '@ionic/react'
import { AxiosError, AxiosResponse } from 'axios'
import { t } from 'i18next'
import { add } from 'ionicons/icons'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {
  appPublicId?: string
  onDismiss: (data?: string | null | undefined | number, role?: string) => void
}
interface StateProps {
  hand: 'right' | 'left'
}
interface DispatchProps {}
interface ModiEditModalProps extends OwnProps, StateProps, DispatchProps {}
const ModiEditModal: FC<ModiEditModalProps> = ({ appPublicId, onDismiss, hand }) => {
  const [modi, setModi] = useState<Modi | any>({
    appPublicId,
  })
  const [inprogress, setInprogress] = useState(false)
  const [toast] = useIonToast()

  const findById = async () => {
    if (appPublicId) {
      try {
        const res: AxiosResponse = await MFactorService.Instance.get(`/v1/tax-profile/${appPublicId ?? ''}`, {})
        if (res.status === 200) {
          setModi(res.data)
        }
      } catch (err) {
        failure(remoteErrorExtractor(err), toast)
      }
    }
  }

  const store = async () => {
    setInprogress(true)
    let actualAppPublicId = appPublicId
    try {
      const resApp: AxiosResponse | any = await AppApiService.Instance.post(
        `/v1/apps/${!!appPublicId ? appPublicId : ''}`,
        { name: modi?.name, appPublicId }
      )
      if (resApp.status === 200 || resApp.status === 201) {
        actualAppPublicId = resApp.data.publicId ?? appPublicId
      }

      const res: AxiosResponse = await MFactorService.Instance.post(`/v1/tax-profile/${actualAppPublicId}`, {
        ...modi,
        appPublicId: actualAppPublicId,
      })
      if (res.status === 200 || res.status === 201) {
        setModi(undefined)
        onDismiss(null, 'done')
      }
    } catch (err: AxiosError | any) {
      log(err)
      failure(remoteErrorExtractor(err), toast)
    }

    setInprogress(false)
  }

  useEffect(() => {
    findById()
  }, [])

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={minorSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => onDismiss(null, 'cancel')}>{t<string>('Cancel')}</IonButton>
          </IonButtons>
          <IonTitle>{t<string>(appPublicId ? 'Edit modi' : 'New modi')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={modi?.name}
              onIonInput={(e) => setModi({ ...modi, name: e.detail.value })}
              label={t('Name')}
              placeholder={t('Name')}
              required={true}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={modi?.nationalId}
              onIonInput={(e) =>
                setModi({
                  ...modi,
                  nationalId: convertToEnglish(e.detail.value),
                })
              }
              label={t('National Id')}
              placeholder={t('National Id')}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={modi?.economicCode}
              onIonInput={(e) =>
                setModi({
                  ...modi,
                  economicCode: convertToEnglish(e.detail.value),
                })
              }
              label={t('Economic Code')}
              placeholder={t('Economic Code')}
              debounce={1500}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            <IonInput
              disabled={inprogress}
              value={modi?.memoryId}
              onIonInput={(e) =>
                setModi({
                  ...modi,
                  memoryId: convertToEnglish(e.detail.value),
                })
              }
              label={t('Memory Id')}
              placeholder={t('Memory Id')}
              debounce={1500}
            ></IonInput>
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            {modi?.privateKey === 'y' && (
              <IonItem lines='none'>
                <IonLabel>{t<string>('Private key')}</IonLabel>
                <IonButton
                  disabled={inprogress}
                  onClick={() =>
                    setModi({
                      ...modi,
                      privateKey: '',
                    })
                  }
                >
                  {t<string>('Edit')}
                </IonButton>
              </IonItem>
            )}
            {modi?.privateKey !== 'y' && (
              <IonTextarea
                disabled={inprogress}
                value={modi?.privateKey}
                onIonInput={(e) =>
                  setModi({
                    ...modi,
                    privateKey: e.detail.value,
                  })
                }
                placeholder={t('Private key')}
                debounce={1500}
                rows={3}
                cols={3}
              ></IonTextarea>
            )}
          </IonCol>
        </IonRow>
        <IonRow className='ion-padding-horizontal'>
          <IonCol>
            {modi?.certificate === 'y' && (
              <IonItem lines='none'>
                <IonLabel>{t<string>('Certificate')}</IonLabel>
                <IonButton
                  disabled={inprogress}
                  onClick={() =>
                    setModi({
                      ...modi,
                      certificate: '',
                    })
                  }
                >
                  {t<string>('Edit')}
                </IonButton>
              </IonItem>
            )}
            {modi?.certificate !== 'y' && (
              <IonTextarea
                disabled={inprogress}
                value={modi?.certificate}
                onIonInput={(e) =>
                  setModi({
                    ...modi,
                    certificate: e.detail.value,
                  })
                }
                placeholder={t('Certificate')}
                debounce={1500}
                rows={3}
                cols={3}
              ></IonTextarea>
            )}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem lines='none'>
              <IonToggle
                disabled={inprogress}
                aria-label={t('Sandbox')}
                labelPlacement='start'
                checked={modi?.sandbox}
                onIonChange={(e) => {
                  setModi({
                    ...modi,
                    sandbox: e.detail.checked,
                  })
                }}
              >
                <IonLabel>{t<string>('Sandbox')}</IonLabel>
              </IonToggle>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButtons slot={mainSlot(hand, document.documentElement.dir)}>
            <IonButton onClick={() => store()} disabled={inprogress} color='primary' fill='outline'>
              {!inprogress && <IonIcon icon={add} slot='start' />}
              {inprogress && <IonSpinner slot='start' />}
              {t<string>('Save')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    hand: state.user.hand,
  }),
  mapDispatchToProps: {},
  component: React.memo(ModiEditModal),
})
