import { success } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { setApp } from '../../data/user/user.actions'
import { App } from '../../models/App'
import { AppApiService } from '../../services/AppApiService'
import { IonButton, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonToolbar, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import { t } from 'i18next'
import React, { FC, useState } from 'react'

interface OwnProps {
  onSuccess?: Function
}
interface DispatchProps {
  setApp: typeof setApp
}
interface AppEditProps extends OwnProps, DispatchProps {}

const AppEdit: FC<AppEditProps> = ({ onSuccess, setApp }) => {
  const [present] = useIonToast()
  const [item, setItem] = useState<App | any>()
  const [wait, setWait] = useState(false)

  const save = async () => {
    setWait(true)
    try {
      const res: AxiosResponse | any = await AppApiService.Instance.post('/v1/apps', item)
      if (res.status === 201) {
        success(t('App created successfully'), present)
      } else if (res.status === 200) {
        success(t('App already exist'), present)
      }
      setApp(res.data)
      setWait(false)
      if (!!onSuccess) {
        onSuccess(item)
      }
    } catch (err: any) {
      present({ message: `${err.message}`, color: 'danger', duration: 3000 })
      setWait(false)
    }
  }

  return (
    <>
      <IonContent className='ion-padding'>
        <IonItem>
          <IonLabel className='required'>{t<string>('Name')}</IonLabel>
          <IonInput value={item?.name} onIonInput={(e) => setItem({ ...item, name: e.detail.value })}></IonInput>
        </IonItem>
      </IonContent>
      <IonFooter>
        <IonToolbar className='ion-margin-horizontal'>
          <IonButton onClick={save} disabled={wait || !item?.name}>
            {t<string>('Save')}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setApp,
  },
  component: React.memo(AppEdit),
})
