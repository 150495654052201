const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
const ID_LENGTH = 16

const generateRandomId = () => {
  let id = ''
  const alphabetLength = ALPHABET.length
  for (let i = 0; i < ID_LENGTH; i++) {
    const randomIndex = Math.floor(Math.random() * alphabetLength)
    id += ALPHABET[randomIndex]
  }
  return id
}

export { generateRandomId }
