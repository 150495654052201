import { connect } from '../data/connect'
import { App } from '../models/App'
import PageContainer from './PageContainer'
import AppListButton from './apps/AppListButton'
import { IonText } from '@ionic/react'
import React, { FC, useEffect } from 'react'

interface OwnProps {
  id: string
  title?: string
  actionButtonsStart?: any
  actionButtonsEnd?: any
  children?: any
}
interface StateProps {
  app?: App
}
interface DispatchProps {}
interface AppContainerProps extends OwnProps, StateProps, DispatchProps {}
const AppContainer: FC<AppContainerProps> = ({ id, app, children, title, actionButtonsStart, actionButtonsEnd }) => {
  useEffect(() => {
    // rerender if app changes
  }, [app])
  return (
    <PageContainer
      id={id}
      isPrivate={true}
      title={title}
      actionButtonsStart={actionButtonsStart}
      actionButtonsEnd={actionButtonsEnd}
    >
      {!!app && <>{children}</>}
      {!app && (
        <>
          <IonText>Please select your </IonText>
          <AppListButton hideSearchIcon={true} />
          <IonText> first</IonText>
        </>
      )}
    </PageContainer>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  component: React.memo(AppContainer),
})
