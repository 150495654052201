import CopyRightAndVersion from '../../components/CopyRightAndVersion'
import { connect } from '../../data/connect'
import { addFactorItem } from '../../data/factor/factor.actions'
import { FactorItem } from '../../models/mfactor/FactorItem'
import PageContainer from '../../pages/PageContainer'
import AppListButton from '../../pages/apps/AppListButton'
import ProductSelectCard from '../product/ProductSelectCard'
import DeskForm from './DeskForm'
import DeskMenuFab from './DeskMenuFab'
import DeskSaveFab from './DeskSaveFab'
import { isPlatform } from '@ionic/core'
import React, { FC, lazy } from 'react'

const DeskScannerFab = lazy(() => import('./DeskScannerFab'))

interface OwnProps {}
interface StateProps {
  showScanner: boolean
  factorItemList: FactorItem[]
  factorSaving: boolean
}
interface DispatchProps {
  addFactorItem: typeof addFactorItem
}
interface DeskProp extends OwnProps, StateProps, DispatchProps {}
const Desk: FC<DeskProp> = ({ showScanner, factorItemList, addFactorItem, factorSaving }) => {
  return (
    <PageContainer
      isPrivate={false}
      id='mfactor-desk'
      actionButtonsStart={<AppListButton hideSearchIcon={true} />}
      footer={<CopyRightAndVersion />}
      padding={!isPlatform('mobile')}
      scrollEvents={true}
    >
      {showScanner && <DeskScannerFab />}
      <DeskMenuFab />
      <DeskSaveFab />
      <DeskForm />
      <DeskSaveFab isFab={false} />
      <div className='ion-padding-vertical'></div>
      <ProductSelectCard factorItemList={factorItemList} addFactorItem={addFactorItem} factorSaving={factorSaving} />
      <div className='ion-padding-vertical'></div>
    </PageContainer>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    showScanner: state.factor.showScanner,
    factorItemList: state.factor.factorItemList,
    factorSaving: state.factor.factorSaving,
  }),
  mapDispatchToProps: {
    addFactorItem,
  },
  component: React.memo(Desk),
})
