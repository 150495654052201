import ActionModal from '../../components/ActionModal'
import { App } from '../../models/App'
import AppEdit from './AppEdit'
import { IonButton, IonIcon } from '@ionic/react'
import { t } from 'i18next'
import { add } from 'ionicons/icons'
import React, { FC, useState } from 'react'

interface OwnProps {
  onSuccess?: (app: App) => void
}
interface AppEditButtonProps extends OwnProps {}
const AppEditButton: FC<AppEditButtonProps> = ({ onSuccess }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      {/* <IonButton onClick={() => setShowModal(true)} className='ion-margin-bottom'>
        <IonIcon icon={add} slot='start'></IonIcon>
        {t<string>('New app')}
      </IonButton> */}
      <ActionModal isOpen={showModal} onDidDismiss={() => setShowModal(false)} id='app-edit'>
        <AppEdit
          onSuccess={async (app: App) => {
            setShowModal(false)
            if (!!onSuccess) onSuccess(app)
          }}
        />
      </ActionModal>
    </>
  )
}

export default React.memo(AppEditButton)
