import { connect } from '../../data/connect'
import { App } from '../../models/App'
import AppContainer from '../../pages/AppContainer'
import AppListButton from '../../pages/apps/AppListButton'
import PersonAddButton from './PersonAddButton'
import PersonList from './PersonList'
import React, { FC } from 'react'

interface OwnProps {}
interface StateProps {
  app?: App
}
interface DispatchProps {}
interface PersonListPageProp extends OwnProps, StateProps, DispatchProps {}
const PersonListPage: FC<PersonListPageProp> = ({ app }) => {
  return (
    <AppContainer
      id='person-list'
      actionButtonsStart={!!app ? <PersonAddButton onDismiss={() => {}} /> : <AppListButton hideSearchIcon={true} />}
    >
      <PersonList />
      <div className='ion-padding-vertical'></div>
    </AppContainer>
  )
}

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    app: state.user.app,
  }),
  mapDispatchToProps: {},
  component: React.memo(PersonListPage),
})
