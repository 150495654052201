import { FactorActions } from './factor.actions'
import { FactorState } from './factor.state'

export const factorReducer = (state: FactorState, action: FactorActions): FactorState => {
  switch (action.type) {
    case 'set-factor-data':
      return { ...state, ...action.data }
    case 'set-factor-item-list':
      return { ...state, factorItemList: action.factorItemList }
    case 'add-factor-item':
      return { ...state, addFactorItem: action.factorItemList }
    case 'remove-factor-item':
      return { ...state, removeFactorItem: action.factorItemList }
    case 'set-refresh-product-list':
      return { ...state, refreshProductList: action.refreshProductList }
    case 'set-refresh-product-select':
      return { ...state, refreshProductSelect: action.refreshProductSelect }
    case 'set-refresh-person-list':
      return { ...state, refreshPersonList: action.refreshPersonList }
    case 'set-refresh-person-select':
      return { ...state, refreshPersonSelect: action.refreshPersonSelect }
    case 'set-refresh-factor-list':
      return { ...state, refreshFactorList: action.refreshFactorList }
    case 'set-syncing':
      return { ...state, syncing: action.syncing }
    case 'set-scan-beep':
      return { ...state, scanBeep: action.scanBeep }
    case 'set-factor-date':
      return { ...state, factorDate: action.factorDate }
    case 'set-factor-payer':
      return { ...state, factorPayer: action.factorPayer }
    case 'set-factor-saving':
      return { ...state, factorSaving: action.factorSaving }
    case 'set-show-scanner':
      return { ...state, showScanner: action.showScanner }
  }
}
