import { error } from '../../components/util/Log'
import TkSpinner from '../../components/util/TkSpinner'
import { failure } from '../../components/util/Toast'
import { connect } from '../../data/connect'
import { setApp, setIsAuthenticated, setNet } from '../../data/user/user.actions'
import { App } from '../../models/App'
import { AppApiService } from '../../services/AppApiService'
import { IonItem, IonLabel, IonSearchbar, useIonToast } from '@ionic/react'
import { AxiosResponse } from 'axios'
import React, { FC, useEffect, useState } from 'react'

interface OwnProps {
  onSuccess?: Function
}
interface StateProps {
  net: boolean
}
interface DispatchProps {
  setApp: typeof setApp
  setNet: typeof setNet
  setIsAuthenticated: typeof setIsAuthenticated
}
interface AppListProps extends OwnProps, StateProps, DispatchProps {}
const AppList: FC<AppListProps> = ({ onSuccess, setApp, net, setIsAuthenticated }) => {
  const [items, setItems] = useState([])
  const [present] = useIonToast()
  const [search, setSearch] = useState('')

  const fetchItems = async () => {
    try {
      setNet(true)
      const res: AxiosResponse = await AppApiService.Instance.get('/v1/apps')
      if (res.status === 200) {
        setItems(res.data)
      }
    } catch (err: any) {
      failure(err?.message, present)
      if (err?.message?.endsWith('401')) {
        setIsAuthenticated(false)
      }
      error('Apps#fetchItems', err)
    }
    setNet(false)
  }

  const select = async (app: App) => {
    await setApp(app)
    if (!!onSuccess) {
      onSuccess(app)
    }
  }

  useEffect(() => {
    fetchItems()
    return () => {}
  }, []) // eslint-disable-line

  return (
    <>
      {net && <TkSpinner />}
      {!net && <IonSearchbar onIonChange={(e: any) => setSearch(e.detail.value?.toLocaleLowerCase())} />}
      {!net &&
        items
          ?.filter((a: App) => a.name?.toLocaleLowerCase()?.includes(search))
          .map((app: App) => (
            <IonItem key={app.publicId} button onClick={() => select(app)}>
              <IonLabel>
                {app.name}
                <p>{app.publicId}</p>
              </IonLabel>
            </IonItem>
          ))}
    </>
  )
}

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    net: state.user.net,
  }),
  mapDispatchToProps: {
    setApp,
    setNet,
    setIsAuthenticated,
  },
  component: React.memo(AppList),
})
